export const voiceSpriteSheet = {
    "a": [0, 273],
    "i": [1000, 380],
    "u": [2000, 230],
    "e": [3000, 310],
    "o": [4000, 320],
    "ka": [5000, 300],
    "ga": [6100, 350],
    "ki": [7000, 250],
    "kya": [8000, 360],
    "kyu": [9000, 350],
    "kyo": [10000, 360],
    "gi": [11000, 280],
    "gya": [12000, 250],
    "gyu": [13000, 220],
    "gyo": [14000, 350],
    "ku": [15000, 290],
    "gu": [16000, 260],
    "ke": [17000, 320],
    "ge": [18000, 420],
    "ko": [19000, 270],
    "go": [20000, 190],
    "sa": [21000, 290],
    "za": [22000, 270],
    "si": [23000, 320],
    "shi": [23000, 320], // shi is the same as si
    "sshi": [23000, 320], // sshi is the same as si
    "sha": [24000, 250],
    "shu": [25000, 270],
    "sho": [26000, 200],
    "ji": [27000, 290],
    "ja": [28000, 400],
    "ju": [29000, 390],
    "jo": [30000, 250],
    "su": [31000, 370],
    "zu": [32000, 420],
    "se": [33000, 380],
    "ze": [34000, 320],
    "so": [35000, 380],
    "zo": [36000, 370],
    "ta": [37000, 250],
    "da": [38000, 160],
    "ti": [39000, 480],
    "chi": [39000, 480], // chi is the same as ti
    "cha": [40000, 330],
    "chu": [41000, 420],
    "cho": [42000, 240],
    "di": [43000, 400],
    "dya": [44000, 420],
    "dyu": [45000, 430],
    "dyo": [46000, 320],
    "tsu": [47000, 220],
    "du": [48000, 350],
    "te": [49000, 300],
    "tte": [49000, 300], // tte is the same as te
    "de": [50000, 200],
    "to": [51000, 300],
    "do": [52000, 240],
    "na": [53000, 320],
    "ni": [54000, 260],
    "nya": [55000, 310],
    "nyu": [56000, 310],
    "nyo": [57000, 300],
    "nu": [58000, 280],
    "ne": [59000, 250],
    "no": [60000, 200],
    "ha": [61000, 240],
    "ba": [62000, 190],
    "pa": [63000, 200],
    "hi": [64000, 230],
    "hya": [65000, 320],
    "hyu": [66000, 220],
    "hyo": [67000, 250],
    "bi": [68000, 270],
    "bya": [69000, 290],
    "byu": [70000, 260],
    "byo": [71000, 230],
    "pi": [72000, 250],
    "pya": [73000, 280],
    "pyu": [74000, 220],
    "pyo": [75000, 260],
    "hu": [76000, 220],
    "fa": [77000, 330],
    "fi": [78000, 260],
    "fe": [79000, 220],
    "fo": [80000, 280],
    "fya": [81000, 270],
    "fyu": [82000, 250],
    "fu": [82000, 250], // fu is the same as fyu
    "fyo": [83000, 250],
    "bu": [84000, 200],
    "pu": [85000, 200],
    "he": [86000, 220],
    "be": [87000, 250],
    "pe": [88000, 150],
    "ho": [89000, 170],
    "bo": [90000, 180],
    "po": [91000, 180],
    "ma": [92000, 320],
    "mi": [93000, 270],
    "mya": [94000, 420],
    "myu": [95000, 300],
    "myo": [96000, 300],
    "mu": [97000, 300],
    "me": [98000, 280],
    "mo": [99000, 230],
    "ya": [100000, 360],
    "yu": [101000, 200],
    "yo": [102000, 300],
    "ra": [103000, 290],
    "ri": [104000, 320],
    "rya": [105000, 300],
    "ryu": [106000, 380],
    "ryo": [107000, 340],
    "ru": [108000, 260],
    "re": [109000, 220],
    "ro": [110000, 180],
    "wa": [111000, 210],
    "wi": [112000, 180],
    "we": [113000, 290],
    "wo": [114000, 230],
    "n": [115000, 200],
    "-": [116000, 150],
    "--": [116000, 350]
}

export const voiceSpeed = 1;